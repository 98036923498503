export const martinLoganPromosData = [
  {
    seriesName: "Helos Series",
    endDate: "2024-12-31",
    startDate: "2024-10-01",
    description:
      "Helos Series speakers deliver legendary MartinLogan quality and performance in compact, high-resolution in-ceiling loudspeakers. Models feature aimable neodymium soft dome tweeters, high-rigidity aluminum cone woofers, and proprietary Vojtko crossover topology.",
    shortDesc: "",
    products: [
      {
        id: 1,
        name: "Helos 12",
        imageURL:
          "https://www.martinlogan.com/uploads/images/helos-12-5a15d13d0bce8.png",
        promo: true,
        msrp_canada: "499.99",
        discount_percentage_canada: "53.71985395",
        discount_percentage_us: "53.71985395",
        msrp_us: "429.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl: "https://www.martinlogan.com/en/product/helos-12",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
    ],
  },
  // {
  //   seriesName: "Wireless",
  //   endDate: "2023-12-31",
  //   startDate: "2023-10-01",
  //   description:
  //     "Upgrade your existing speakers or stereo system to stream wireless music with DTS Play-Fi or Apple Airplay.",
  //   shortDesc: "Offer available exclusively through martinlogan.com",
  //   products: [
  //     {
  //       id: 1,
  //       name: "Forte",
  //       imageURL:
  //         "https://www.martinlogan.com/uploads/images/forte-5a61239715b57.png",
  //       promo: true,
  //       msrp_canada: "699.00",
  //       discount_percentage_canada: "75",
  //       discount_percentage_us: "75",
  //       msrp_us: "599.99",
  //       endDate: "2023-12-31",
  //       startDate: "2023-10-01",
  //       productPageUrl: "https://www.martinlogan.com/en/product/forte",
  //       buttonLabel: "Buy Now",
  //     },
  //     {
  //       id: 2,
  //       name: "Unison",
  //       imageURL: "https://cdn.pmlsound.com/martinlogan/unison-5a15c.png",
  //       promo: true,
  //       msrp_canada: "499.00",
  //       discount_percentage_canada: "75",
  //       discount_percentage_us: "75",
  //       msrp_us: "399.99",
  //       endDate: "2023-12-31",
  //       startDate: "2023-10-01",
  //       productPageUrl: "https://www.martinlogan.com/en/product/unison",
  //       buttonLabel: "Buy Now",
  //     },
  //     {
  //       id: 3,
  //       name: "Bravado",
  //       imageURL:
  //         "https://www.martinlogan.com/uploads/images/bravado-5a6123c7aa3d2.png",
  //       promo: true,
  //       msrp_canada: "799.00",
  //       discount_percentage_canada: "75",
  //       discount_percentage_us: "75",
  //       msrp_us: "699.99",
  //       endDate: "2023-12-31",
  //       startDate: "2023-10-01",
  //       productPageUrl: "https://www.martinlogan.com/en/product/bravado",
  //       buttonLabel: "Buy Now",
  //     },
  //   ],
  // },
  {
    seriesName: "Masterpiece™ Series",
    endDate: "2024-08-04",
    startDate: "2024-05-29",
    description:
      "The absolute biggest, boldest, most outrageous performance possible, the Masterpiece Series is the pinnacle of today's electrostatic loudspeaker design. From Home Theater to extreme 2-channel listening, this is the art of Truth in Sound. Our next generation of electrostatic loudspeakers with three decades of audio design and engineering innovation, distilled into a startling fresh loudspeaker ideal.",
    shortDesc: "",
    products: [
      {
        id: 1,
        name: "Renaissance ESL 15A",
        imageURL:
          "https://www.martinlogan.com/uploads/images/renaissance-esl-15a-5a15aa1e57905.png",
        promo: true,
        msrp_canada: "37499.98",
        discount_percentage_canada: "10",
        discount_percentage_us: "10",
        msrp_us: "28999.98",
        endDate: "2024-08-04",
        startDate: "2024-05-29",
        productPageUrl:
          "https://www.martinlogan.com/en/product/renaissance-esl-15a",
        buttonLabel: "",
        uom: "pr",
      },
      {
        id: 2,
        name: "Expression ESL 13A",
        imageURL:
          "https://www.martinlogan.com/uploads/images/expression-esl-13a-5a15aa46b7be3.png",
        promo: true,
        msrp_canada: "23499.98",
        discount_percentage_canada: "10",
        discount_percentage_us: "10",
        msrp_us: "17999.98",
        endDate: "2024-08-04",
        startDate: "2024-05-29",
        productPageUrl:
          "https://www.martinlogan.com/en/product/expression-esl-13a",
        buttonLabel: "",
        uom: "pr",
      },
      {
        id: 3,
        name: "Impression ESL 11A",
        imageURL:
          "https://www.martinlogan.com/uploads/images/impression-esl-11a-5a15aa5be40a0.png",
        promo: true,
        msrp_canada: "15499.98",
        discount_percentage_canada: "10",
        discount_percentage_us: "10",
        msrp_us: "11999.98",
        endDate: "2024-08-04",
        startDate: "2024-05-29",
        productPageUrl:
          "https://www.martinlogan.com/en/product/impression-esl-11a",
        buttonLabel: "",
        uom: "pr",
      },
      {
        id: 4,
        name: "Classic ESL 9",
        imageURL:
          "https://www.martinlogan.com/uploads/images/classic-esl-9-5a15aa95dcf1b.png",
        promo: true,
        msrp_canada: "9499.98",
        discount_percentage_canada: "10",
        discount_percentage_us: "10",
        msrp_us: "7499.98",
        endDate: "2024-08-04",
        startDate: "2024-05-29",
        productPageUrl: "https://www.martinlogan.com/en/product/classic-esl-9",
        buttonLabel: "",
        uom: "pr",
      },
      {
        id: 5,
        name: "Illusion ESL C34A",
        imageURL:
          "https://www.martinlogan.com/uploads/images/illusion-esl-c34a-5a15ae7e2615d.png",
        promo: true,
        msrp_canada: "12499.99",
        discount_percentage_canada: "10",
        discount_percentage_us: "10",
        msrp_us: "9499.99",
        endDate: "2024-08-04",
        startDate: "2024-05-29",
        productPageUrl:
          "https://www.martinlogan.com/en/product/illusion-esl-c34a",
        buttonLabel: "",
        uom: "ea",
      },
      {
        id: 6,
        name: "Focus ESL C18",
        imageURL:
          "https://www.martinlogan.com/uploads/images/focus-esl-c18-5a15ae9e40ff5.png",
        promo: true,
        msrp_canada: "6499.99",
        discount_percentage_canada: "10",
        discount_percentage_us: "10",
        msrp_us: "4899.99",
        endDate: "2024-08-04",
        startDate: "2024-05-29",
        productPageUrl: "https://www.martinlogan.com/en/product/focus-esl-c18",
        buttonLabel: "",
        uom: "ea",
      },
    ],
  },
  {
    seriesName: "Motion® Foundation Series",
    endDate: "2024-12-31",
    startDate: "2024-10-01",
    description:
      "MartinLogan Foundation Series products set new standards in affordable performance by offering many of MartinLogan’s signature high-performance technologies at approachable prices.",
    shortDesc: "",
    products: [
      {
        id: 1,
        name: "Motion Foundation F2",
        imageURL:
          "https://cdn.pmlsound.com/martinlogan/motion-foundation-promotion-thumbnails-F2.png",
        promo: true,
        msrp_canada: "1249.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_us: "999.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl:
          "https://www.martinlogan.com/en/product/motion-foundation-f2",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
      {
        id: 2,
        name: "Motion Foundation F1",
        imageURL:
          "https://cdn.pmlsound.com/martinlogan/motion-foundation-promotion-thumbnails-F1.png",
        promo: true,
        msrp_canada: "999.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_us: "749.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl:
          "https://www.martinlogan.com/en/product/motion-foundation-f1",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
      {
        id: 3,
        name: "Motion Foundation B2",
        imageURL:
          "https://cdn.pmlsound.com/martinlogan/motion-foundation-promotion-thumbnails-B2.png",
        promo: true,
        msrp_canada: "499.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_us: "399.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl:
          "https://www.martinlogan.com/en/product/motion-foundation-b2",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
      {
        id: 4,
        name: "Motion Foundation B1",
        imageURL:
          "https://cdn.pmlsound.com/martinlogan/motion-foundation-promotion-thumbnails-B1.png",
        promo: true,
        msrp_canada: "399.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_us: "299.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl:
          "https://www.martinlogan.com/en/product/motion-foundation-b1",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
      {
        id: 5,
        name: "Motion Foundation C1",
        imageURL:
          "https://cdn.pmlsound.com/martinlogan/motion-foundation-promotion-thumbnails-C1.png",
        promo: true,
        msrp_canada: "649.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_us: "499.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl:
          "https://www.martinlogan.com/en/product/motion-foundation-c1",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
    ],
  },
  {
    seriesName: "Factory Refurbished",
    endDate: "2024-01-03",
    startDate: "2023-11-21",
    description:
      "Every MartinLogan Factory Refurbished product undergoes an extensive recertification process, including meticulous testing that matches the exact functional standards set for brand-new MartinLogan products. Your refurbished factory product not only performs as effectively as a new one but is also supported by a one-year warranty.",
    shortDesc: "Offer available exclusively through martinlogan.com",
    products: [
      {
        id: 1,
        name: "Motion 20i RFB",
        imageURL:
          "https://www.martinlogan.com/uploads/images/motion-20i-5a15b0dc24e93.png",
        promo: true,
        msrp_canada: "1299.99",
        discount_percentage_canada: "50",
        discount_percentage_us: "50",
        msrp_us: "999.99",
        endDate: "2024-01-03",
        startDate: "2023-10-01",
        productPageUrl: "https://www.martinlogan.com/en/product/motion-20i-rfb",
        buttonLabel: "Buy Now",
      },
    ],
  },

  {
    seriesName: "Outdoor Living Series",
    endDate: "2024-09-30",
    startDate: "2024-03-18",
    description:
      "Rugged, all-weather materials, completely weather- and water-proof construction, and endless mounting and placement options make MartinLogan's Outdoor Living Audio System the best way to expand the reach of your high performance audio entertainment.",
    shortDesc: "",
    products: [
      {
        id: 1,
        name: "Outdoor Living Foundation 8.1 System",
        imageURL:
          "https://martinlogan.com/uploads/images/ml-outdoor-living-foundation-system.png",
        promo: true,
        msrp_canada: "3299.99",
        discount_percentage_canada: null,
        discount_percentage_us: null,
        discount_amount: "800",
        msrp_us: "2999.99",
        endDate: "2024-09-30",
        startDate: "2024-03-18",
        productPageUrl:
          "https://www.martinlogan.com/en/product/outdoor-living-foundation-81-system",
        buttonLabel: "Buy Now",
        uom: "sys",
      },
    ],
  },
  {
    seriesName: "MP Series",
    endDate: "2024-09-30",
    startDate: "2024-03-18",
    description:
      "Multi Purpose DSP amplifiers covering a variety of Custom Installation solutions.",
    shortDesc: "",
    products: [
      {
        id: 1,
        name: "MP500",
        imageURL:
          "https://www.martinlogan.com/uploads/images/ML-MP500%20-%20front-web.png",
        promo: true,
        msrp_canada: "999.99",
        discount_percentage_canada: null,
        discount_percentage_us: null,
        discount_amount: "100",
        msrp_us: "799.99",
        endDate: "2024-09-30",
        startDate: "2024-03-18",
        productPageUrl: "https://www.martinlogan.com/en/product/mp500",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
    ],
  },
  {
    seriesName: "Motion® Series",
    endDate: "2024-12-31",
    startDate: "2024-10-01",
    description:
      "Inspired by the clarity and detail of MartinLogan’s ultra-high-end electrostatic loudspeakers, Motion® 2i, 4i, 6i, and 8i feature exquisite Folded Motion tweeters and powerful bass technology in compact and affordable packages. <br/> Motion SLM speakers provides a clean, easy to install solution for elevating the aural experience that has been sacrificed with ultra-thin TV technology.",
    shortDesc: "",
    products: [
      {
        id: 1,
        name: "Motion 2i",
        imageURL:
          "https://www.martinlogan.com/uploads/images/motion-2i-5a15bc6618669.png",
        promo: true,
        msrp_canada: "199.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        discount_amount: "100",
        msrp_us: "199.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl: "https://www.martinlogan.com/en/product/motion-2i",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
      {
        id: 2,
        name: "Motion 4i",
        imageURL:
          "https://www.martinlogan.com/uploads/images/motion-4i-5a15bbe74fb6a.png",
        promo: true,
        msrp_canada: "299.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        discount_amount: "100",
        msrp_us: "249.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl: "https://www.martinlogan.com/en/product/motion-4i",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
      {
        id: 3,
        name: "Motion 6i",
        imageURL:
          "	https://www.martinlogan.com/uploads/images/motion-6i-5a15bcf2723b0.png",
        promo: true,
        msrp_canada: "349.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        discount_amount: "100",
        msrp_us: "299.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl: "https://www.martinlogan.com/en/product/motion-6i",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
      {
        id: 4,
        name: "Motion 8i",
        imageURL:
          "https://www.martinlogan.com/uploads/images/motion-8i-5a15bccddbb48.png",
        promo: true,
        msrp_canada: "499.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        discount_amount: "100",
        msrp_us: "449.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl: "https://www.martinlogan.com/en/product/motion-8i",
        buttonLabel: "Buy Now",
        uom: "ea",
      },

      {
        id: 5,
        name: "Motion SLM",
        imageURL:
          "https://martinlogan.com/uploads/images/motion-slm-5a15bd8bb6740.png",
        promo: true,
        msrp_canada: "599.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        discount_amount: "100",
        msrp_us: "549.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl: "https://www.martinlogan.com/en/product/motion-slm",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
      {
        id: 6,
        name: "Motion SLM XL",
        imageURL:
          "https://martinlogan.com/uploads/images/motion-slm-xl-5a15bd6890bae.png",
        promo: true,
        msrp_canada: "899.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        discount_amount: "100",
        msrp_us: "749.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl: "https://www.martinlogan.com/en/product/motion-slm-xl",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
      {
        id: 7,
        name: "Motion SLM X3",
        imageURL:
          "https://martinlogan.com/uploads/images/motion-slm-x3-5a15bdab269e1.png",
        promo: true,
        msrp_canada: "1249.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        discount_amount: "100",
        msrp_us: "1199.99",
        endDate: "2024-12-31",
        startDate: "2024-10-01",
        productPageUrl: "https://www.martinlogan.com/en/product/motion-slm-x3",
        buttonLabel: "Buy Now",
        uom: "ea",
      },
    ],
  },
];
