import React, { useContext, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { martinLoganPromosData } from "../utils/MartinLoganPromosData";
import moment from "moment";
import { appContext } from "../../../App";
import DocumentTitle from "../../../components/DocumentTitle";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MartinLoganPromosLandingPage = () => {
  const { country } = useContext(appContext);

  const today = new Date().toISOString().slice(0, 10);

  console.log(today);

  const isAnyPromoActive = martinLoganPromosData.filter((item) => {
    if (
      moment() >= moment(item.startDate) &&
      moment() <= moment(item.endDate)
    ) {
      return true;
    } else {
      return false;
    }

    // return item;
  });

  const calculateDiscountedPrice = (msrp, percentage) => {
    const afterDiscountPrice = (
      Number(msrp) -
      (Number(msrp) * Number(percentage)) / 100
    ).toFixed(2);

    return afterDiscountPrice;
  };

  return (
    <>
      <DocumentTitle title={"MartinLogan Promos"} />
      <div className="bg-white bg-[url('https://www.martinlogan.com/bundles/front/images/masterpiece-brushstrokes.png')] bg-no-repeat bg-top bg-center">
        <Header />
        <div id="banner" className="">
          <img
            class=""
            // src="https://cdn.pmlsound.com/martinlogan/ml-promotions-header.jpg"
            // src="https://cdn.pmlsound.com/martinlogan/ml-promotions-header_new.jpg"
            src={
              today <= "2024-12-31"
                ? "https://cdn.pmlsound.com/martinlogan/ml-fall-promotions-header.jpg"
                : today < "2024-09-30"
                ? "https://cdn.pmlsound.com/martinlogan/ml-spring-promotions-header.jpg"
                : "https://cdn.pmlsound.com/martinlogan/ml-promotions-header.jpg"
            }
            // src="https://cdn.pmlsound.com/martinlogan/EXTENDED-ml-summer-promotions-header.jpg"
            alt="No promos are available at the moment. Please check later."
          />
        </div>
        <section id="body" className="bg-gray-100/10 py-20">
          {/* <div id="banner" className="max-w-7xl mx-auto">
            <h1 className="text-center font-logo lg:text-8xl text-5xl font-bold text-red-700 py-10">
              MartinLogan Fall Promos
            </h1>
          </div> */}
          <div id="promo-body" className="max-w-[76rem] mx-auto">
            {isAnyPromoActive.length ? (
              isAnyPromoActive?.map((_) => {
                return (
                  <div
                    className="grid xl:grid-cols-12 grid-cols-1 py-4 lg:px-0 px-6 "
                    id={_.seriesName}
                    key={_.id}
                  >
                    <div
                      id="series-name"
                      className="col-span-4 divide-y-8 divide-gray-100/10 xl:px-0 px-8 xl:py-0 py-6"
                    >
                      <p className="text-4xl font-bold">{_.seriesName}</p>
                      <p
                        className="text-lg font-light pr-6 font-logo"
                        dangerouslySetInnerHTML={{ __html: _.description }}
                      ></p>
                      {_.seriesName === "Factory Refurbished" ? (
                        <>
                          <p>
                            <span className="font-bold font-logo text-3xl ">
                              <span className="text-red-600">Sale Ends</span>{" "}
                              while supplies last.
                            </span>
                          </p>
                          <p className="text-xl font-light pr-6 font-logo">
                            {_.shortDesc}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="text-3xl font-bold font-logo">
                            <span className="text-3xl text-red-600">
                              Sale Ends on
                            </span>{" "}
                            {moment(_.endDate).format("MMMM Do YYYY")}
                          </p>
                          <p className="text-xl font-light pr-6 font-logo">
                            {_.shortDesc}
                          </p>
                        </>
                      )}
                    </div>

                    <div
                      id="products-list"
                      className="col-span-8 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 xl:px-0 px-6"
                    >
                      {_.products.map((item, index) => {
                        return (
                          <div className="relative">
                            {moment() >= moment(item.startDate) &&
                            moment() <= moment(item.endDate) ? (
                              <>
                                <div
                                  className="rounded-3xl border-solid border border-gray-200 shadow-md shadow-gray-500/40"
                                  key={item.id}
                                >
                                  <div className="bg-gray-300 rounded-t-3xl flex place-content-center">
                                    <img
                                      src={item.imageURL}
                                      className={classNames(
                                        // item.name.includes("") ||
                                        // item.name.includes("20i") ||
                                        item.name.includes("Renaissance") ||
                                          item.name.includes("Expression") ||
                                          item.name.includes("Impression") ||
                                          item.name.includes("Classic")
                                          ? "w-[39%] h-full"
                                          : item.name.includes("Motion 2i")
                                          ? "w-[50%] h-full"
                                          : item.name.includes("Motion 4i")
                                          ? "w-[58%] h-full"
                                          : item.name.includes("Motion 6i")
                                          ? "w-[82%] h-full"
                                          : item.name.includes("Motion SLM X3")
                                          ? "w-full h-full"
                                          : item.name.includes("Motion SLM")
                                          ? "w-[25.5%] h-full"
                                          : item.name.includes("Motion SLM XL")
                                          ? "w-[50%] h-full"
                                          : "w-full h-full",
                                        "rounded-t-3xl bg-gray-300 p-3"
                                      )}
                                      alt="product_image"
                                    />
                                  </div>
                                  <div className="bg-gray-50 rounded-b-3xl">
                                    <h4 className="text-2xl font-bold px-6 pt-6 pb-2">
                                      {item.name}
                                    </h4>
                                    {item.discount_percentage_canada === null ||
                                    item.discount_percentage_us === null ? (
                                      <div className="text-base font-light leading-7 px-6 pb-6">
                                        <p className="flex gap-2">
                                          {country === "CA" ||
                                          country === "US" ? (
                                            <span className="text-red-600 font-bold text-xl">
                                              $
                                              {country === "CA"
                                                ? item.msrp_canada -
                                                  item.discount_amount
                                                : item.msrp_us -
                                                  item.discount_amount}
                                            </span>
                                          ) : null}
                                          <span
                                            className={classNames(
                                              country === "CA" ||
                                                country === "US"
                                                ? "line-through"
                                                : "",
                                              "text-gray-900  font-medium text-md"
                                            )}
                                          >
                                            $
                                            {country === "CA"
                                              ? item.msrp_canada
                                              : item.msrp_us}{" "}
                                          </span>
                                          <span className="text-xs mt-1.5 font-semibold">
                                            ({item.uom})
                                          </span>
                                        </p>
                                        {country === "CA" ||
                                        country === "US" ? (
                                          <span className="text-sm text-gray-600 font-medium">
                                            Save $
                                            {country === "CA"
                                              ? item.discount_amount
                                              : item.discount_amount}
                                            {/* <span className="font-light">
                                              (
                                              {country === "CA"
                                                ? `${item.discount_percentage_canada}% OFF`
                                                : `${item.discount_percentage_us}% OFF`}
                                              )
                                            </span> */}
                                          </span>
                                        ) : null}
                                      </div>
                                    ) : (
                                      <div className="text-base font-light leading-7 px-6 pb-6">
                                        <p className="flex gap-2">
                                          {country === "CA" ||
                                          country === "US" ? (
                                            <span className="text-red-600 font-bold text-xl">
                                              $
                                              {country === "CA"
                                                ? calculateDiscountedPrice(
                                                    item.msrp_canada,
                                                    item.discount_percentage_canada
                                                  )
                                                : calculateDiscountedPrice(
                                                    item.msrp_us,
                                                    item.discount_percentage_us
                                                  )}
                                            </span>
                                          ) : null}
                                          <span
                                            className={classNames(
                                              country === "CA" ||
                                                country === "US"
                                                ? "line-through"
                                                : "",
                                              "text-gray-900  font-medium text-md"
                                            )}
                                          >
                                            $
                                            {country === "CA"
                                              ? item.msrp_canada
                                              : item.msrp_us}
                                          </span>
                                          <span className="text-xs mt-1.5 font-semibold">
                                            ({item.uom})
                                          </span>
                                        </p>
                                        {country === "CA" ||
                                        country === "US" ? (
                                          <span className="text-sm text-gray-600 font-medium">
                                            Save $
                                            {country === "CA"
                                              ? (
                                                  item.msrp_canada -
                                                  calculateDiscountedPrice(
                                                    item.msrp_canada,
                                                    item.discount_percentage_canada
                                                  )
                                                ).toFixed(2)
                                              : (
                                                  item.msrp_us -
                                                  calculateDiscountedPrice(
                                                    item.msrp_us,
                                                    item.discount_percentage_us
                                                  )
                                                ).toFixed(2)}{" "}
                                            <span className="font-light">
                                              (
                                              {country === "CA"
                                                ? `${item.discount_percentage_canada}% OFF`
                                                : `${item.discount_percentage_us}% OFF`}
                                              )
                                            </span>
                                          </span>
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="cursor-pointer rounded-3xl opacity-0 hover:opacity-100 bg-gray-200 duration-300 absolute inset-0 z-10 gap-6 flex flex-col justify-center items-center text-xl text-black font-semibold">
                                  {country === "CA" || country === "US" ? (
                                    <a
                                      href={item.productPageUrl}
                                      target="_blank"
                                      className="cursor-pointer"
                                      rel="noreferrer"
                                    >
                                      {item.buttonLabel}
                                    </a>
                                  ) : null}
                                  {!item.name.includes("Forte") &&
                                  !item.name.includes("Unison") &&
                                  !item.name.includes("Bravado") &&
                                  !item.name.includes("RFB") ? (
                                    <a
                                      href="https://www.martinlogan.com/en/dealers"
                                      target="_blank"
                                      className="cursor-pointer"
                                      rel="noreferrer"
                                    >
                                      Find a Dealer
                                    </a>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            ) : (
              <h2 className="font-logo text-5xl font-medium text-center h-96">
                No promos are active at this time. Please check later.
              </h2>
            )}
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default MartinLoganPromosLandingPage;
