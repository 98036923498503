export const ParadigmPromosData = [
  {
    seriesName: "Defiance Subwoofers",
    seriesHash: "DefianceSubwoofers",
    seriesImgURL: "https://www.paradigm.com/img/c/62_thumb.jpg",
    seriesSalePercentage: "20",
    endDate: "2024-09-30",
    startDate: "2024-08-29",
    description:
      "Pure power, unleashed by clean, efficient amplification, in six models, from entry-level to the ultimate earthshaking performance. With app control and Anthem Room Correction (ARC®), Defiance puts tremendous, perfectly balanced bass at your fingertips.",
    products: [
      {
        id: 1,
        name: "Defiance V8",
        imageURL: "https://www.paradigm.com/2285-large_default/defiance-v8.jpg",
        promo: true,
        msrp_canada: "579.99",
        discountedPrice_canada: "463.99",
        price_diffrence_canada: "116.00",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        msrp_us: "479.99",
        discountedPrice_us: "383.99",
        price_diffrence_us: "96.00",
        endDate: "2024-09-30",
        startDate: "2024-08-29",
        productPageUrl: "https://www.paradigm.com/en/ported/defiance-v8",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 2,
        name: "Defiance V10",
        imageURL:
          "https://www.paradigm.com/2277-large_default/defiance-v10.jpg",
        promo: true,
        msrp_canada: "779.99",
        discountedPrice_canada: "623.99",
        price_diffrence_canada: "156.00",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_us: "679.99",
        discountedPrice_us: "543.99",
        price_diffrence_us: "136.00",
        endDate: "2024-09-30",
        startDate: "2024-08-29",
        productPageUrl: "https://www.paradigm.com/en/ported/defiance-v10",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 3,
        name: "Defiance V12",
        imageURL:
          "https://www.paradigm.com/2271-large_default/defiance-v12.jpg",
        promo: true,
        msrp_canada: "879.99",
        discountedPrice_canada: "703.99",
        price_diffrence_canada: "176.00",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        msrp_us: "779.99",
        discountedPrice_us: "623.99",
        price_diffrence_us: "156.00",
        endDate: "2024-09-30",
        startDate: "2024-08-29",
        productPageUrl: "https://www.paradigm.com/en/ported/defiance-v12",
        buttonLabel: "Buy Now",
        available: true,
      },
    ],
  },
  {
    seriesName: "Cinema Series",
    seriesHash: "CinemaSeries",
    seriesImgURL: "	https://www.paradigm.com/img/c/43_thumb.jpg",
    seriesSalePercentage: "20",
    endDate: "2024-09-30",
    startDate: "2024-08-29",
    description:
      "Paradigm performance in a compact, custom matched multi-channel music and home theater audio system, including a complete 5.1 array.",
    products: [
      {
        id: 1,
        name: "Cinema Sub",
        imageURL: "https://www.paradigm.com/2345-large_default/cinema-sub.jpg",
        promo: true,
        msrp_canada: "499.99",
        discountedPrice_canada: "2474.99",
        price_diffrence_canada: "749.99",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        msrp_us: "449.99",
        discountedPrice_us: "3399.99",
        price_diffrence_us: "599.99",
        endDate: "2024-09-30",
        startDate: "2024-08-29",
        productPageUrl: "https://www.paradigm.com/en/ported/cinema-sub",
        buttonLabel: "Buy Now",
        available: true,
      },
    ],
  },
  {
    seriesName: "Monitor SE Series",
    seriesHash: "MonitorSESeries",
    seriesImgURL: "https://www.paradigm.com/img/c/56_thumb.jpg",
    seriesSalePercentage: "30",
    endDate: "2024-01-03",
    startDate: "2023-10-03",
    description:
      "The stylish and affordable new Monitor SE Series is the perfect introduction to the world of high-performance audio. It’s packed with state-of-the-art Paradigm technology, the kind of stuff usually found only on speakers that cost much more.",
    products: [
      {
        id: 1,
        name: "Monitor SE 2000C",
        imageURL:
          "https://www.paradigm.com/2511-large_default/monitor-se-2000c.jpg",
        promo: true,
        msrp_canada: "249.99",
        discountedPrice_canada: "187.49",
        price_diffrence_canada: "62.50",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_us: "249.99",
        discountedPrice_us: "187.49",
        price_diffrence_us: "62.50",
        startDate: "2023-10-02",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/center/monitor-se-2000c",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 2,
        name: "Monitor SE <br/> Atom",
        imageURL:
          "https://www.paradigm.com/2518-large_default/monitor-se-atom.jpg",
        promo: true,
        msrp_canada: "359.98",
        discountedPrice_canada: "269.98",
        price_diffrence_canada: "90.00",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_us: "299.99",
        discountedPrice_us: "224.99",
        price_diffrence_us: "75.00",
        startDate: "2023-10-02",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/passive/monitor-se-atom",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 3,
        name: "Monitor SE 3000F",
        imageURL:
          "https://www.paradigm.com/2498-large_default/monitor-se-3000f.jpg",
        promo: true,
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_canada: "429.99",
        discountedPrice_canada: "322.49",
        price_diffrence_canada: "107.50",
        msrp_us: "349.99",
        discountedPrice_us: "262.49",
        price_diffrence_us: "87.50",
        startDate: "2023-10-02",
        endDate: "2024-01-03",
        productPageUrl:
          "https://www.paradigm.com/en/floorstanding/monitor-se-3000f",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 4,
        name: "Monitor SE 6000F",
        imageURL:
          "https://www.paradigm.com/2485-large_default/monitor-se-6000f.jpg",
        promo: true,
        msrp_canada: "549.99",
        discountedPrice_canada: "412.49",
        price_diffrence_canada: "137.50",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_us: "499.99",
        discountedPrice_us: "374.99",
        price_diffrence_us: "125.00",
        startDate: "2023-10-02",
        endDate: "2024-01-03",
        productPageUrl:
          "https://www.paradigm.com/en/floorstanding/monitor-se-6000f",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 5,
        name: "Monitor SE 8000F",
        imageURL:
          "https://www.paradigm.com/2686-large_default/monitor-se-8000f.jpg",
        promo: true,
        msrp_canada: "999.99",
        discountedPrice_canada: "699.99",
        price_diffrence_canada: "299.99",
        discount_percentage_canada: "30",
        discount_percentage_us: "30",
        msrp_us: "849.99",
        discountedPrice_us: "254.99",
        price_diffrence_us: "594.99",
        startDate: "2023-10-02",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/dealer-locator",
        buttonLabel: "Find a Dealer",
        available: true,
      },
      {
        id: 6,
        name: "Surround 3",
        imageURL: "https://www.paradigm.com/2009-large_default/surround-3.jpg",
        promo: true,
        msrp_canada: "549.99",
        // discountedPrice_canada: "699.99",
        // price_diffrence_canada: "299.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_us: "479.99",
        // discountedPrice_us: "254.99",
        // price_diffrence_us: "594.99",
        startDate: "2023-10-02",
        endDate: "2024-01-03",
        productPageUrl:
          "https://www.paradigm.com/en/rears-surrounds/surround-3",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 7,
        name: "Surround 1",
        imageURL: "https://www.paradigm.com/2000-large_default/surround-1.jpg",
        promo: true,
        msrp_canada: "379.99",
        // discountedPrice_canada: "699.99",
        // price_diffrence_canada: "299.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_us: "329.99",
        // discountedPrice_us: "254.99",
        // price_diffrence_us: "594.99",
        startDate: "2023-10-02",
        endDate: "2024-01-03",
        productPageUrl:
          "https://www.paradigm.com/en/rears-surrounds/surround-1",
        buttonLabel: "Buy Now",
        available: true,
      },
    ],
  },
  {
    seriesName: "Premier Series",
    seriesHash: "PremierSeries",
    seriesImgURL: "https://www.paradigm.com/img/c/57_thumb.jpg",
    seriesSalePercentage: "15",
    startDate: "2023-10-16",
    endDate: "2024-01-03",
    description:
      "You’ve been thinking about it for a while, and now is the time to take that next step. Step up from mass-market audio to a superior sound experience, with the new Premier Series of affordable high-performance loudspeakers.",
    products: [
      {
        id: 1,
        name: "Premier 100B",
        imageURL:
          "https://www.paradigm.com/2625-large_default/premier-100b.jpg",
        promo: true,
        msrp_canada: "549.99",
        discountedPrice_canada: "467.49",
        price_diffrence_canada: "82.50",
        msrp_us: "399.99",
        discountedPrice_us: "339.99",
        price_diffrence_us: "60.00",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        startDate: "2023-10-16",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/passive/premier-100b",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 2,
        name: "Premier 200B",
        imageURL:
          "https://www.paradigm.com/2602-large_default/premier-200b.jpg",
        promo: true,
        msrp_canada: "649.99",
        discountedPrice_canada: "552.49",
        price_diffrence_canada: "97.50",
        msrp_us: "499.99",
        discountedPrice_us: "424.99",
        price_diffrence_us: "75.00",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        startDate: "2023-10-16",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/passive/premier-200b",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 3,
        name: "Premier 700F",
        imageURL:
          "https://www.paradigm.com/2552-large_default/premier-700f.jpg",
        promo: true,
        msrp_canada: "1049.99",
        discountedPrice_canada: "892.49",
        price_diffrence_canada: "157.50",
        msrp_us: "899.99",
        discountedPrice_us: "764.99",
        price_diffrence_us: "135.00",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        startDate: "2023-10-16",
        endDate: "2024-01-03",
        productPageUrl:
          "https://www.paradigm.com/en/floorstanding/premier-700f",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 4,
        name: "Premier 500C",
        imageURL:
          "https://www.paradigm.com/2584-large_default/premier-500c.jpg",
        promo: true,
        msrp_canada: "1049.99",
        discountedPrice_canada: "892.49",
        price_diffrence_canada: "157.50",
        msrp_us: "849.99",
        discountedPrice_us: "722.49",
        price_diffrence_us: "127.50",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        startDate: "2023-10-16",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/center/premier-500c",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 5,
        name: "Premier 600C",
        imageURL:
          "https://www.paradigm.com/2566-large_default/premier-600c.jpg",
        promo: true,
        msrp_canada: "1299.99",
        discountedPrice_canada: "1104.99",
        price_diffrence_canada: "195.00",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "1099.99",
        discountedPrice_us: "934.99",
        price_diffrence_us: "165.00",
        startDate: "2023-10-16",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/center/premier-600c",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 6,
        name: "Premier 800F",
        imageURL:
          "	https://www.paradigm.com/2532-large_default/premier-800f.jpg",
        promo: true,
        msrp_canada: "1349.99",
        discountedPrice_canada: "1147.49",
        price_diffrence_canada: "202.50",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "1199.99",
        discountedPrice_us: "1019.99",
        price_diffrence_us: "180.00",
        startDate: "2023-10-16",
        endDate: "2024-01-03",
        productPageUrl:
          "https://www.paradigm.com/en/floorstanding/premier-800f",
        buttonLabel: "Buy Now",
        available: true,
      },
    ],
  },
  {
    seriesName: "Founder Series",
    seriesHash: "FounderSeries",
    seriesImgURL:
      "https://cdn.pmlsound.com/paradigm/paradigm-founder-series-logo-black.png",
    seriesSalePercentage: "15",
    startDate: "2023-11-20",
    endDate: "2024-01-03",
    description:
      "The Founder Series of premium loudspeakers includes six models that can serve in a high-end two-channel system or in a multi-channel configuration ideally suited for the latest movie soundtracks and their object-oriented codecs.",
    products: [
      {
        id: 1,
        name: "Founder 40B",
        imageURL: "https://www.paradigm.com/2863-large_default/founder-40b.jpg",
        promo: true,
        msrp_canada: "1449.99",
        discountedPrice_canada: "1232.49",
        price_diffrence_canada: "217.50",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "1199.99",
        discountedPrice_us: "1019.99",
        price_diffrence_us: "180.00",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/passive/founder-40b",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 2,
        name: "Founder 70LCR",
        imageURL:
          "https://www.paradigm.com/3047-large_default/founder-70lcr.jpg",
        promo: true,
        msrp_canada: "2299.99",
        discountedPrice_canada: "1954.99",
        price_diffrence_canada: "345.00",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "1799.99",
        discountedPrice_us: "1529.99",
        price_diffrence_us: "270",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/center/founder-70lcr",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 3,
        name: "Founder 80F",
        imageURL: "https://www.paradigm.com/2831-large_default/founder-80f.jpg",
        promo: true,
        msrp_canada: "2399.99",
        discountedPrice_canada: "2039.99",
        price_diffrence_canada: "360.00",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "1949.99",
        discountedPrice_us: "1657.49",
        price_diffrence_us: "292.00",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/floorstanding/founder-80f",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 4,
        name: "Founder 100F",
        imageURL:
          "https://www.paradigm.com/2799-large_default/founder-100f.jpg",
        promo: true,
        msrp_canada: "3399.99",
        discountedPrice_canada: "2889.99",
        price_diffrence_canada: "510.00",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "2799.99",
        discountedPrice_us: "2379.99",
        price_diffrence_us: "420.00",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl:
          "https://www.paradigm.com/en/floorstanding/founder-100f",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 5,
        name: "Founder 90C",
        imageURL: "https://www.paradigm.com/2924-large_default/founder-90c.jpg",
        promo: true,
        msrp_canada: "3399.99",
        discountedPrice_canada: "2889.99",
        price_diffrence_canada: "510.00",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "2899.99",
        discountedPrice_us: "2464.99",
        price_diffrence_us: "435.00",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/center/founder-90c",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 6,
        name: "Founder 120H",
        imageURL:
          "https://www.paradigm.com/2830-large_default/founder-120h.jpg",
        promo: true,
        msrp_canada: "5499.99",
        discountedPrice_canada: "4674.99",
        price_diffrence_canada: "825.00",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "4499.99",
        discountedPrice_us: "3824.99",
        price_diffrence_us: "675.00",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl:
          "https://www.paradigm.com/en/floorstanding/founder-120h",
        buttonLabel: "Buy Now",
        available: true,
      },
    ],
  },
  {
    seriesName: "XR Series",
    seriesHash: "XRSeries",
    seriesImgURL: "https://www.paradigm.com/img/c/90_thumb.jpg",
    seriesSalePercentage: "15",
    endDate: "2024-01-03",
    startDate: "2023-11-20",
    description:
      "The all-new XR subwoofers are a master class in design and engineering refinement that challenges what is possible from a compact, sealed subwoofer design. Our goal was to push the boundaries of performance while never losing sight of aesthetics by wrapping everything up into a suitable size that won't ever feel out of place.",
    products: [
      {
        id: 1,
        name: "XR 11",
        imageURL: "https://www.paradigm.com/3330-large_default/xr-11.jpg",
        promo: true,
        msrp_canada: "4999.99",
        discountedPrice_canada: "4249.99",
        price_diffrence_canada: "749.99",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "3999.99",
        discountedPrice_us: "3399.99",
        price_diffrence_us: "599.99",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/sealed/xr-11",
        buttonLabel: "Buy Now",
        available: true,
      },

      {
        id: 2,
        name: "XR 13",
        imageURL: "https://www.paradigm.com/3412-large_default/xr-13.jpg",
        promo: true,
        msrp_canada: "5999.99",
        discountedPrice_canada: "5099.99",
        price_diffrence_canada: "899.99",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "4999.99",
        discountedPrice_us: "4249.99",
        price_diffrence_us: "749.99",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/sealed/xr-13",
        buttonLabel: "Buy Now",
        available: true,
      },
    ],
  },
  {
    seriesName: "Persona Series",
    seriesHash: "PersonaSeries",
    seriesImgURL: "https://www.paradigm.com/img/c/54_thumb.jpg",
    seriesSalePercentage: "15",
    startDate: "2023-11-20",
    endDate: "2024-01-03",
    description:
      "This is it: the new Paradigm, stylishly delivering an unprecedented level of audio realism. Our flagship showcase of vanguard materials, design acumen, and our most advanced technologies, all entirely Crafted in Canada.",
    products: [
      {
        id: 1,
        name: "Persona B",
        imageURL: "https://www.paradigm.com/2411-large_default/persona-b.jpg",
        promo: true,
        msrp_canada: "4249.99",
        // discountedPrice_canada: "3612.49",
        // price_diffrence_canada: "637.49",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "3749.99",
        // discountedPrice_us: "3187.49",
        // price_diffrence_us: "562.49",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/dealer-locator",
        buttonLabel: "Find a Dealer",
        available: true,
      },
      {
        id: 2,
        name: "Persona 3F",
        imageURL: "https://www.paradigm.com/2400-large_default/persona-3f.jpg",
        promo: true,
        msrp_canada: "6899.99",
        // discountedPrice_canada: "5864.99",
        // price_diffrence_canada: "1034.99",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "5999.99",
        // discountedPrice_us: "5099.99",
        // price_diffrence_us: "899.99",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/dealer-locator",
        buttonLabel: "Find a Dealer",
        available: true,
      },
      {
        id: 3,
        name: "Persona Sub",
        imageURL: "https://www.paradigm.com/2352-large_default/persona-sub.jpg",
        promo: true,
        msrp_canada: "7999.99",
        // discountedPrice_canada: "6799.99",
        // price_diffrence_canada: "1199.99",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "7499.99",
        // discountedPrice_us: "6374.99",
        // price_diffrence_us: "1124.99",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/dealer-locator",
        buttonLabel: "Find a Dealer",
        available: true,
      },
      {
        id: 4,
        name: "Persona C",
        imageURL: "https://www.paradigm.com/2418-large_default/persona-c.jpg",
        promo: true,
        msrp_canada: "8999.99",
        // discountedPrice_canada: "7649.99",
        // price_diffrence_canada: "1349.99",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "7999.99",
        // discountedPrice_us: "6799.99",
        // price_diffrence_us: "1199.99",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/dealer-locator",
        buttonLabel: "Find a Dealer",
        available: true,
      },
      {
        id: 5,
        name: "Persona 5F",
        imageURL: "https://www.paradigm.com/2388-large_default/persona-5f.jpg",
        promo: true,
        msrp_canada: "10499.99",
        // discountedPrice_canada: "8924.99",
        // price_diffrence_canada: "1574.99",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "8999.99",
        // discountedPrice_us: "7649.99",
        // price_diffrence_us: "1349.99",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/dealer-locator",
        buttonLabel: "Find a Dealer",
        available: true,
      },
      {
        id: 6,
        name: "Persona 7F",
        imageURL: "https://www.paradigm.com/2378-large_default/persona-7f.jpg",
        promo: true,
        msrp_canada: "14999.99",
        // discountedPrice_canada: "12749.99",
        // price_diffrence_canada: "2249.99",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "13499.99",
        // discountedPrice_us: "11474.99",
        // price_diffrence_us: "2024.99",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/dealer-locator",
        buttonLabel: "Find a Dealer",
        available: true,
      },
      {
        id: 7,
        name: "Persona 9H",
        imageURL: "https://www.paradigm.com/2367-large_default/persona-9h.jpg",
        promo: true,
        msrp_canada: "19999.99",
        // discountedPrice_canada: "16999.99",
        // price_diffrence_canada: "2999.99",
        discount_percentage_canada: "15",
        discount_percentage_us: "15",
        msrp_us: "18499.99",
        // discountedPrice_us: "15724.99",
        // price_diffrence_us: "2774.99",
        startDate: "2023-10-20",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/dealer-locator",
        buttonLabel: "Find a Dealer",
        available: true,
      },
    ],
  },
  // {
  //   seriesName: "Premium Wireless Series",
  //   seriesHash: "PremiumWirelessSeries",
  //   seriesImgURL: "https://www.paradigm.com/img/c/59_thumb.jpg",
  //   seriesSalePercentage: "75",
  //   startDate: "2023-11-20",
  //   endDate: "2024-01-03",
  //   description:
  //     'Paradigm’s Premium Wireless Series is the only wireless streaming system to feature reference-level performance. It combines our innovative loudspeaker and amplifier engineering with state-of-the-art DTS Play-Fi® wireless streaming technology + "Works with Alexa" certification. Finally, you can experience the freedom of wireless audio streaming with bold, dynamic performance.',
  //   products: [
  //     {
  //       id: 12,
  //       name: "PW Link",
  //       imageURL: "https://www.paradigm.com/2670-large_default/pw-link.jpg",
  //       promo: true,
  //       msrp_canada: "449.00",
  //       discount_percentage_canada: "75",
  //       discount_percentage_us: "75",
  //       msrp_us: "349.00",
  //       startDate: "2023-10-16",
  //       endDate: "2024-01-03",
  //       productPageUrl: "",
  //       buttonLabel: "Sold Out",
  //       available: false,
  //     },
  //     {
  //       id: 11,
  //       name: "PW 300",
  //       imageURL: "https://www.paradigm.com/934-large_default/pw-300.jpg",
  //       promo: true,
  //       msrp_canada: "399.00",
  //       discount_percentage_canada: "75",
  //       discount_percentage_us: "75",
  //       msrp_us: "329.99",
  //       startDate: "2023-10-16",
  //       endDate: "2024-01-03",
  //       productPageUrl: "https://www.paradigm.com/en/wireless/pw-300",
  //       buttonLabel: "Buy now",
  //       available: true,
  //     },

  //     {
  //       id: 10,
  //       name: "PW 600",
  //       imageURL: "https://www.paradigm.com/920-large_default/pw-600.jpg",
  //       promo: true,
  //       msrp_canada: "699.00",
  //       discount_percentage_canada: "75",
  //       discount_percentage_us: "75",
  //       msrp_us: "599.00",
  //       startDate: "2023-10-16",
  //       endDate: "2024-01-03",
  //       productPageUrl: "",
  //       buttonLabel: "Sold Out",
  //       available: false,
  //     },
  //     {
  //       id: 9,
  //       name: "PW 800",
  //       imageURL: "https://www.paradigm.com/921-large_default/pw-800.jpg",
  //       promo: true,
  //       msrp_canada: "899.00",
  //       discount_percentage_canada: "75",
  //       discount_percentage_us: "75",
  //       msrp_us: "799.00",
  //       startDate: "2023-10-16",
  //       endDate: "2024-01-03",
  //       productPageUrl: "",
  //       buttonLabel: "Sold Out",
  //       available: false,
  //     },
  //   ],
  // },
  {
    seriesName: "Factory Refurbished",
    seriesHash: "FactoryRefurbished",
    seriesImgURL:
      "https://cdn.pmlsound.com/paradigm/Factory-Refurbished-logos_black.png",
    seriesSalePercentage: "60",
    startDate: "2023-11-20",
    endDate: "2024-01-03",
    description:
      "Every Paradigm Factory Refurbished product undergoes an extensive recertification process, including meticulous testing that matches the exact functional standards set for brand-new Paradigm products. Your refurbished factory product not only performs as effectively as a new one but is also supported by a one-year warranty.",
    products: [
      {
        id: 3,
        name: "Premier 700F RFB",
        imageURL:
          "https://www.paradigm.com/2552-large_default/premier-700f.jpg",
        promo: true,
        msrp_canada: "1049.99",
        discountedPrice_canada: "892.49",
        price_diffrence_canada: "157.50",
        msrp_us: "899.99",
        discountedPrice_us: "764.99",
        price_diffrence_us: "135.00",
        discount_percentage_canada: "35",
        discount_percentage_us: "35",
        startDate: "2023-10-16",
        endDate: "2024-01-03",
        productPageUrl:
          "https://www.paradigm.com/en/floorstanding/premier-700f-rfb",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 4,
        name: "Premier 500C RFB",
        imageURL:
          "https://www.paradigm.com/2584-large_default/premier-500c.jpg",
        promo: true,
        msrp_canada: "1049.99",
        discountedPrice_canada: "892.49",
        price_diffrence_canada: "157.50",
        msrp_us: "849.99",
        discountedPrice_us: "722.49",
        price_diffrence_us: "127.50",
        discount_percentage_canada: "35",
        discount_percentage_us: "35",
        startDate: "2023-10-16",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/center/premier-500c-rfb",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 5,
        name: "Premier 600C RFB",
        imageURL:
          "https://www.paradigm.com/2566-large_default/premier-600c.jpg",
        promo: true,
        msrp_canada: "1299.99",
        discountedPrice_canada: "1104.99",
        price_diffrence_canada: "195.00",
        discount_percentage_canada: "35",
        discount_percentage_us: "35",
        msrp_us: "1099.99",
        discountedPrice_us: "934.99",
        price_diffrence_us: "165.00",
        startDate: "2023-10-16",
        endDate: "2024-01-03",
        productPageUrl: "https://www.paradigm.com/en/center/premier-600c-rfb",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 6,
        name: "Premier 800F RFB",
        imageURL:
          "	https://www.paradigm.com/2532-large_default/premier-800f.jpg",
        promo: true,
        msrp_canada: "1349.99",
        discountedPrice_canada: "1147.49",
        price_diffrence_canada: "202.50",
        discount_percentage_canada: "35",
        discount_percentage_us: "35",
        msrp_us: "1199.99",
        discountedPrice_us: "1019.99",
        price_diffrence_us: "180.00",
        startDate: "2023-10-16",
        endDate: "2024-01-03",
        productPageUrl:
          "https://www.paradigm.com/en/floorstanding/premier-800f-rfb",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 7,
        name: "Rock Monitor 60-SM RFB",
        imageURL:
          "https://www.paradigm.com/1957-large_default/rock-monitor-60-sm.jpg",
        promo: true,
        msrp_canada: "429.99",
        discount_percentage_canada: "60",
        discount_percentage_us: "60",
        msrp_us: "399.99",
        startDate: "2023-10-16",
        endDate: "2024-01-03",
        productPageUrl:
          "https://www.paradigm.com/en/outdoor-speakers/rock-monitor-60-sm-rfb",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 8,
        name: "Rock Monitor 80-SM RFB",
        imageURL:
          "https://www.paradigm.com/1958-large_default/rock-monitor-80-sm.jpg",
        promo: true,
        msrp_canada: "599.99",
        discount_percentage_canada: "60",
        discount_percentage_us: "60",
        msrp_us: "549.99",
        startDate: "2023-10-16",
        endDate: "2024-01-03",
        productPageUrl: "",
        buttonLabel: "Sold Out",
        available: false,
      },
    ],
  },
  {
    seriesName: "Garden Oasis Series",
    seriesHash: "GardenOasisSeries",
    seriesImgURL: "https://www.paradigm.com/img/c/60_thumb.jpg",
    seriesSalePercentage: "25",
    endDate: "2024-06-30",
    startDate: "2024-04-15",
    description:
      "Paradigm means no limits! Now enjoy superior audio performance combined with a rugged weatherproof design and innovative Paradigm RED™ technology in Paradigm’s first-ever landscape loudspeaker system: Garden Oasis.",
    products: [
      {
        id: 1,
        name: "Garden Oasis Essentials Outdoor System",
        imageURL:
          "https://www.paradigm.com/2697-large_default/garden-oasis-essentials-outdoor-system.jpg",
        promo: true,
        msrp_canada: "3299.99",
        discountedPrice_canada: "2474.99",
        price_diffrence_canada: "749.99",
        discount_percentage_canada: "25",
        discount_percentage_us: "25",
        msrp_us: "2799.99",
        discountedPrice_us: "3399.99",
        price_diffrence_us: "599.99",
        endDate: "2024-06-30",
        startDate: "2024-04-15",
        productPageUrl:
          "https://www.paradigm.com/en/garden-oasis-series/garden-oasis-essentials-outdoor-system",
        buttonLabel: "",
        available: true,
      },
    ],
  },
  {
    seriesName: "Stylus Series",
    seriesHash: "StylusSeries",
    seriesImgURL: "https://www.paradigm.com/img/c/71_thumb.jpg",
    seriesSalePercentage: "20",
    endDate: "2024-06-30",
    startDate: "2024-04-15",
    description:
      "When it comes to high-performance outdoor sound, Paradigm has your backyard covered! Contemporary Stylus speakers are an easy, stylish and surprisingly affordable way to bring the Paradigm high-performance sound you enjoy indoors … outdoors.",
    products: [
      {
        id: 1,
        name: "Stylus 470",
        imageURL: "https://www.paradigm.com/2102-large_default/stylus-470.jpg",
        promo: true,
        msrp_canada: "999.99",
        discountedPrice_canada: "2474.99",
        price_diffrence_canada: "749.99",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        msrp_us: "849.99",
        discountedPrice_us: "3399.99",
        price_diffrence_us: "599.99",
        endDate: "2024-06-30",
        startDate: "2024-04-15",
        productPageUrl:
          "https://www.paradigm.com/en/outdoor-speakers/stylus-470",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 2,
        name: "Stylus 370",
        imageURL: "https://www.paradigm.com/2095-large_default/stylus-370.jpg",
        promo: true,
        msrp_canada: "799.99",
        discountedPrice_canada: "2474.99",
        price_diffrence_canada: "749.99",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        msrp_us: "649.99",
        discountedPrice_us: "3399.99",
        price_diffrence_us: "599.99",
        endDate: "2024-06-30",
        startDate: "2024-04-15",
        productPageUrl:
          "https://www.paradigm.com/en/outdoor-speakers/stylus-370",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 3,
        name: "Stylus 270",
        imageURL: "https://www.paradigm.com/2092-large_default/stylus-270.jpg",
        promo: true,
        msrp_canada: "559.99",
        discountedPrice_canada: "2474.99",
        price_diffrence_canada: "749.99",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        msrp_us: "449.99",
        discountedPrice_us: "3399.99",
        price_diffrence_us: "599.99",
        endDate: "2024-06-30",
        startDate: "2024-04-15",
        productPageUrl:
          "https://www.paradigm.com/en/outdoor-speakers/stylus-270",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 4,
        name: "Stylus 470-SM",
        imageURL:
          "https://www.paradigm.com/2107-large_default/stylus-470-sm.jpg",
        promo: true,
        msrp_canada: "599.99",
        discountedPrice_canada: "2474.99",
        price_diffrence_canada: "749.99",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        msrp_us: "474.99",
        discountedPrice_us: "3399.99",
        price_diffrence_us: "599.99",
        endDate: "2024-06-30",
        startDate: "2024-04-15",
        productPageUrl:
          "https://www.paradigm.com/en/outdoor-speakers/stylus-470-sm",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 5,
        name: "Stylus 370-SM",
        imageURL:
          "https://www.paradigm.com/2099-large_default/stylus-370-sm.jpg",
        promo: true,
        msrp_canada: "499.99",
        discountedPrice_canada: "2474.99",
        price_diffrence_canada: "749.99",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        msrp_us: "374.99",
        discountedPrice_us: "3399.99",
        price_diffrence_us: "599.99",
        endDate: "2024-06-30",
        startDate: "2024-04-15",
        productPageUrl:
          "https://www.paradigm.com/en/outdoor-speakers/stylus-370-sm",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 6,
        name: "Stylus 170",
        imageURL: "https://www.paradigm.com/2087-large_default/stylus-170.jpg",
        promo: true,
        msrp_canada: "359.99",
        discountedPrice_canada: "2474.99",
        price_diffrence_canada: "749.99",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        msrp_us: "349.99",
        discountedPrice_us: "3399.99",
        price_diffrence_us: "599.99",
        endDate: "2024-06-30",
        startDate: "2024-04-15",
        productPageUrl:
          "https://www.paradigm.com/en/outdoor-speakers/stylus-170",
        buttonLabel: "Buy Now",
        available: true,
      },
    ],
  },
  {
    seriesName: "Rock Monitor™ Series",
    seriesHash: "RockMonitorSeries",
    seriesImgURL: "https://www.paradigm.com/img/c/73_thumb.jpg",
    seriesSalePercentage: "20",
    endDate: "2024-06-30",
    startDate: "2024-04-15",
    description:
      "These high-performance single-speaker systems provide two channels of L/R sound outdoors. Their Dual-Directional Soundfield™ — two tweeters, each offset at a 30° angle—and twin voice coil bass/midrange driver ensure extremely wide dispersion, a particularly important consideration outdoors where there are no walls or ceiling to contain sound.",
    products: [
      {
        id: 1,
        name: "Rock Monitor 80-SM",
        imageURL:
          "https://www.paradigm.com/1958-large_default/rock-monitor-80-sm.jpg",
        promo: true,
        msrp_canada: "599.99",
        discountedPrice_canada: "2474.99",
        price_diffrence_canada: "749.99",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        msrp_us: "549.99",
        discountedPrice_us: "3399.99",
        price_diffrence_us: "599.99",
        endDate: "2024-06-30",
        startDate: "2024-04-15",
        productPageUrl:
          "https://www.paradigm.com/en/outdoor-speakers/rock-monitor-60-sm",
        buttonLabel: "Buy Now",
        available: true,
      },
      {
        id: 2,
        name: "Rock Monitor 60-SM",
        imageURL:
          "https://www.paradigm.com/1957-large_default/rock-monitor-60-sm.jpg",
        promo: true,
        msrp_canada: "429.99",
        discountedPrice_canada: "2474.99",
        price_diffrence_canada: "749.99",
        discount_percentage_canada: "20",
        discount_percentage_us: "20",
        msrp_us: "399.99",
        discountedPrice_us: "3399.99",
        price_diffrence_us: "599.99",
        endDate: "2024-06-30",
        startDate: "2024-04-15",
        productPageUrl:
          "https://www.paradigm.com/en/outdoor-speakers/rock-monitor-60-sm",
        buttonLabel: "Buy Now",
        available: true,
      },
    ],
  },
];
